var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { title: "Create a new Team" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.close } }, [
            _vm._v(" Cancel "),
          ]),
          _c(
            "a-button",
            {
              key: "submit",
              staticClass: "create-btn",
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleForm },
            },
            [_vm._v("\n      Create\n    ")]
          ),
        ],
        1
      ),
      _c(
        "a-form",
        { attrs: { form: _vm.form, layout: "vertical" } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "Team Name" } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "club_id",
                        {
                          rules: [
                            {
                              required: true,
                              message: "Please select a club.",
                            },
                          ],
                        },
                      ],
                      expression:
                        "[\n          'club_id',\n          {\n            rules: [{ required: true, message: 'Please select a club.' }],\n          },\n        ]",
                    },
                  ],
                  staticStyle: { "margin-bottom": "25px" },
                  attrs: { placeholder: "Select a club" },
                  model: {
                    value: _vm.club_id,
                    callback: function ($$v) {
                      _vm.club_id = $$v
                    },
                    expression: "club_id",
                  },
                },
                _vm._l(_vm.clubList, function (club, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: club.id } },
                    [
                      _vm._v(
                        "\n          " + _vm._s(club.club_name) + "\n        "
                      ),
                    ]
                  )
                }),
                1
              ),
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "teamName",
                      {
                        rules: [
                          {
                            required: true,
                            message: "The team name is required",
                          },
                          {
                            min: 3,
                            message:
                              "The team name must be at least 3 characters",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n          'teamName',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'The team name is required',\n              },\n              {\n                min: 3,\n                message: 'The team name must be at least 3 characters',\n              },\n            ],\n          },\n        ]",
                  },
                ],
                attrs: { placeholder: "E.G Under 17s Soccer" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }