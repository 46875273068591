<template>
  <a-modal v-model="isVisible" title="Create a new Team" @cancel="close">
    <template slot="footer">
      <a-button key="back" @click="close"> Cancel </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        class="create-btn"
        @click="handleForm"
      >
        Create
      </a-button>
    </template>
    <a-form :form="form" layout="vertical">
      <a-form-item label="Team Name">
        <a-select
          style="margin-bottom: 25px"
          v-model="club_id"
          v-decorator="[
            'club_id',
            {
              rules: [{ required: true, message: 'Please select a club.' }],
            },
          ]"
          placeholder="Select a club"
        >
          <a-select-option
            v-for="(club, index) in clubList"
            :key="index"
            :value="club.id"
          >
            {{ club.club_name }}
          </a-select-option>
        </a-select>

        <a-input
          v-decorator="[
            'teamName',
            {
              rules: [
                {
                  required: true,
                  message: 'The team name is required',
                },
                {
                  min: 3,
                  message: 'The team name must be at least 3 characters',
                },
              ],
            },
          ]"
          placeholder="E.G Under 17s Soccer"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import notifications from "@/common/notifications/notification.service";
import { teamService } from "@/common/api/api.service";
import { mapGetters } from "vuex";
import { AUTH_USER } from "@/store/modules/auth/auth-actions";
import { memberService } from "../../../../common/api/api.service";
export default {
  name: "NewTeamModal",
  props: {
    visible: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      clubList: [],
      club_id: null,
    };
  },
  mounted() {
    this.fetchClubList();
  },
  computed: {
    ...mapGetters({
      user: AUTH_USER,
    }),
    isVisible: {
      get() {
        return this.visible;
      },
      set() {
        return false;
      },
    },
  },
  methods: {
    fetchClubList() {
      const params = {
        role: this.user.select_role,
      };
      memberService.ClubList(params).then((resp) => {
        this.clubList = resp.data.result;
      });
    },
    handleForm() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.handleFormSubmit({
            role: this.user.select_role,
            ...values,
          });
        }
      });
    },
    handleFormSubmit(values) {
      this.loading = true;
      teamService
        .put(values)
        .then((resp) => {
          console.log(resp, "hjg");
          this.loading = false;
          if (resp.data.success) {
            notifications.success("Team Created successfully");
            this.close();
          } else if (resp.data.code === 404) {
            notifications.warn("There was a problem creating this team");
            this.close();
          } else if (resp.data.code === 422) {
            notifications.warn("test");
          } else {
            notifications.warn("There was a problem creating this team");
          }
        })
        .catch((err) => {
          notifications.warn(err.response.data.message);
          this.loading = false;
        });
    },
    close() {
      this.form.resetFields();
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.create-btn {
  width: 120px !important;
}
</style>
